<template>
  <v-layout v-if="!isLoaded" column fill-height align-center justify-center>
    <AppErrorCard
      v-if="hasError"
      entity="key priorities"
      :is-anonymous-user="loggedInUser.isAnonymous"
      :is-invited="!!$route.query.invited"
      :invitee-email="$route.query.email"
      :show-auth-loader="showAuthLoader"
      @reload="reloadKeyPrioritiesDetails"
      @login-with-google="$emit('login-with-google')"
      @login-with-microsoft="$emit('login-with-microsoft')"
      @login-with-email-pass="$emit('login-with-email-pass', $event)"
      @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
      @reset-password="$emit('reset-password', $event)"
      @update-password="$emit('update-password', $event)"
    />

    <AppLoader v-else />
  </v-layout>
  <div v-else>
    <AppPageToolbar
      ref="toolbar"
      entity="key priorities"
      :entity-name="meta.name"
      :visibility="meta.visibility"
      :users="data.users"
      :domains="meta.permissions.domains"
      :logged-in-user="loggedInUser"
      :tw-sync-options="meta.twSyncOptions"
      allow-sync
      @update-name="updateName"
      @update-privacy="updatePrivacy"
      @update-permissions="updatePermissions"
      @delete="deleteKeyPriorities"
      @open-import-export-dialog="openImportExportModal"
      @set-message="setMessage"
      @initialize-tw-oauth-process="$refs.appTWSync.initiateTeamworkOAuth()"
      @show-sync-options="$refs.appTWSync.showDialogSyncOptions = true"
      @save-sync-options="$refs.appTWSync.saveTWAuthTokenAndURL($event)"
    />

    <div class="eos-keypriorities__content-wrapper">
      <v-layout pa-0 ma-0 fill-height>
        <!-- Drawer -->
        <v-flex
          v-show="showDrawer"
          class="eos-keypriorities__drawer"
          :style="{
            minWidth: `${drawerWidth}px`,
            maxWidth: `${drawerWidth}px`,
          }"
        >
          <v-list class="py-0">
            <v-list-item-group v-model="activeDrawerItem" mandatory>
              <v-list-item v-for="(item, index) in drawerItems" :key="`sidebar-item-${index}`" color="primary">
                <v-list-item-content class="px-1">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-flex>

        <!-- List -->
        <v-flex>
          <v-toolbar dense flat class="eos-keypriorities__toolbar">
            <v-app-bar-nav-icon @click="showDrawer = !showDrawer" />
            <v-toolbar-title class="pl-1">
              {{ selectedCategory }}
              Priorities
            </v-toolbar-title>
            <div class="d-flex flex-grow-1 flex-wrap align-center ml-5">
              <v-badge
                v-for="status in keyPriorityStatuses"
                :key="`status-badge-${status.id}`"
                :content="status.count"
                :color="status.color"
                class="mr-5 my-1"
                inline
                left
              >
                <span class="ml-1 text-caption text-md-body-2">{{ status.text }}</span>
              </v-badge>
              <div v-if="canSync" class="ml-auto">
                <AppSyncBtn
                  text="Sync with Teamwork"
                  :syncing="syncing"
                  @sync="syncWithTeamwork"
                />
              </div>
            </div>
          </v-toolbar>

          <div v-if="hasPriorities" class="eos-keypriorities__content">
            <v-alert color="warning" dense dismissible dark class="text-body-2 ma-3">
              This tool is still in BETA. Please let us know your feedback or report any issues to
              <a href="mailto: support@instantagencytools.com" class="white--text">support@instantagencytools.com</a>
              Thanks!
            </v-alert>

            <v-toolbar flat dense>
              <v-toolbar-title>
                <small>Key Priorities</small>
              </v-toolbar-title>

              <v-spacer />
              <KeyPrioritiesFilterStatus
                v-if="hasFilteredPriorities"
                v-model="selectedStatusFilters"
                :key="selectedCategory"
                :statuses="keyPriorityStatuses"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="addKeyPriority()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Key Priority</span>
              </v-tooltip>
            </v-toolbar>

            <div class="v-data-table theme--light">
              <div class="v-data-table__wrapper">
                <draggable
                  v-if="hasFilteredPriorities"
                  v-model="data.priorities"
                  v-bind="{
                    ghostClass: 'ghost',
                    disabled: catFilteredPriorities.length < 2
                  }"
                  tag="table"
                  @end="updatePriorities"
                >
                  <template v-for="(item, index) in data.priorities">
                    <tbody
                      v-if="(selectedCategory === 'All' || item.type === selectedCategory) && (selectedStatusFilters.length === 0 || selectedStatusFilters.includes(item.status))"
                      :key="`key-priority-${index}`"
                      :class="{ 'drag--disabled': catFilteredPriorities.length < 2 }"
                    >
                      <KeyPriorityListItem
                        :users="data.users"
                        :item="item"
                        :with-type="activeDrawerItem === 0"
                        :is-expanded="expandedItemIds.includes(item.id)"
                        is-expandable
                        with-title
                        with-deliverables
                        @toggle="toggleDetails"
                        @edit="editKeyPriority"
                        @update-date="updateDate"
                        @delete="openDialogKeyPriorityConfirmDelete"
                      >
                        <div slot="actions" class="d-flex align-center">
                          <v-tooltip v-if="item.taskId && meta.twSyncOptions.domain" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" size="14px" class="mr-2 cursor-pointer" v-text="'$vuetify.icons.tw-teamwork'" @click="goToTWTask(item.taskId)"/>
                            </template>
                            <span>Open Teamwork Task</span>
                          </v-tooltip>
                          <v-tooltip v-if="userL10s.length > 0" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon small v-on="on" class="mr-2" @click="linkWithL10(item.id)">mdi-calendar</v-icon>
                            </template>
                            <span>Link with Weekly Meeting</span>
                          </v-tooltip>
                          <v-tooltip v-if="userVTOs.length > 0" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon small v-on="on" class="mr-1" @click="linkWithVTO(item.id)">mdi-binoculars</v-icon>
                            </template>
                            <span>Link with Vision & Plan</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" class="mr-1" @click="addKeyPriority(item)">mdi-plus</v-icon>
                            </template>
                            <span>Add Deliverable</span>
                          </v-tooltip>
                        </div>
                      </KeyPriorityListItem>
                      <v-expand-transition>
                        <KeyPriorityListItemDetails
                          v-show="expandedItemIds.includes(item.id)"
                          :key="activeDrawerItem"
                          :description="item.description"
                          @edit-desc="e => editKeyPriority({ item, e })"
                        >
                          <div v-if="item.deliverables && item.deliverables.length > 0" class="pl-3 pb-3 grey lighten-4" slot="deliverables">
                            <div class="v-data-table theme--light">
                              <div class="v-data-table__wrapper grey lighten-4">
                                <draggable
                                  v-model="item.deliverables"
                                  v-bind="{
                                    ghostClass: 'ghost',
                                    disabled: item.deliverables.length < 2
                                  }"
                                  tag="table"
                                  style="width: 100%;"
                                  @end="updatePriorities"
                                >
                                  <tbody
                                    v-for="(dl, deliverableIndex) in item.deliverables"
                                    :key="`key-priority-dl-${deliverableIndex}`"
                                  >
                                    <KeyPriorityListItem
                                      :users="data.users"
                                      :item="dl"
                                      :is-expanded="expandedItemIds.includes(dl.id)"
                                      with-title
                                      class="white"
                                      @toggle="toggleDetails"
                                      @edit="editKeyPriority"
                                      @update-date="updateDate"
                                      @delete="openDialogKeyPriorityConfirmDelete"
                                    >
                                      <div slot="actions" class="d-flex align-center">
                                        <v-tooltip v-if="dl.taskId && meta.twSyncOptions.domain" bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" size="14px" class="mr-2 cursor-pointer" v-text="'$vuetify.icons.tw-teamwork'" @click="goToTWTask(dl.taskId)"/>
                                          </template>
                                          <span>Open Teamwork sub-task</span>
                                        </v-tooltip>
                                      </div>
                                    </KeyPriorityListItem>

                                    <KeyPriorityListItemDetails
                                      v-show="expandedItemIds.includes(dl.id)"
                                      :description="dl.description"
                                      persistent-description
                                      class="white"
                                      @edit-desc="e => editKeyPriority({ item: dl, e })"
                                    />
                                  </tbody>
                                </draggable>
                              </div>
                            </div>
                          </div>
                        </KeyPriorityListItemDetails>
                      </v-expand-transition>
                    </tbody>
                  </template>
                </draggable>
                <p v-else class="text-center">
                  No <span class="text-lowercase">{{ selectedCategory }}</span> priorities found
                </p>
                <v-btn v-if="hasFilteredPriorities" depressed small class="ma-3" @click="addKeyPriority()">
                  <v-icon x-small class="pr-2">mdi-plus</v-icon>Add Key Priority
                </v-btn>
              </div>
            </div>
          </div>
          <v-btn v-else small outlined class="d-flex mt-8 mx-auto" @click="addKeyPriority()">
            Add Key Priority
          </v-btn>
        </v-flex>
      </v-layout>
    </div>

    <KeyPriorityDialogForm
      v-model="showDialogKeyPrioritiesForm"
      :tw-sync-options="meta.twSyncOptions"
      :tw-projects="twProjects"
      :tw-tasklists="twTasklists"
      :title="keyPriorityFields.parentRockId ? 'Deliverable' : 'Key Priority'"
      :users="data.users"
      :fields="keyPriorityFields"
      :saving="isSavingKP"
      @manage-users="$refs.toolbar.managePermissions()"
      @save="updateKeyPriorityFields($event, false)"
      @save-next="updateKeyPriorityFields($event, true)"
      @delete="openDialogKeyPriorityConfirmDelete"
      @set-message="setMessage"
      @close="closeDialogKeyPriorityForm"
      @refresh-tasklist="fetchTasklists"
    />

    <AppDialogConfirmDelete
      v-if="showDialogKeyPriorityConfirmDelete"
      :message="`You want to delete this ${keyPriorityToDelete.parentRockId ? 'deliverable' : 'key priority'}?`"
      @confirm="deleteKeyPriority"
      @cancel="closeDialogKeyPriorityConfirmDelete"
    />

    <AppDialogJSONImportExport
      v-if="showImportExportModal"
      title="Key Priorities Data"
      allow-update
      :dataJSON="dataJSON"
      :show-loader="isUpdatingKeyPrioritiesJSON"
      @update-data="updateKeyPrioritiesJSON"
      @close="closeImportExportModal"
    />

    <KeyPriorityDialogLinks
      v-if="showLinksDialog"
      :fetching-items="isFetchingLinkedItems"
      :show-loader="isUpdatingLinks"
      :title="linkDialogTitle"
      :data="linkDialogData"
      :selected-ids="selectedIds"
      @update-relation="updateKeyPriorityRelations"
      @close="closeLinksDialog"
    />

    <AppTeamworkSync
      ref="appTWSync"
      collection-name="keypriorities"
      entity="key priorities"
      :doc-id="$route.params.keyPrioritiesId"
      :tw-sync-options="meta.twSyncOptions"
      @tw-projects="projects => twProjects = projects"
      @tw-tasklists="tasklists => twTasklists = tasklists"
      @sync-options-added="setSyncOptions"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

import AppPageToolbar from '@/components/shared/toolbars/AppPageToolbar'
import AppDialogJSONImportExport from '@/components/shared/dialogs/AppDialogJSONImportExport'
import AppErrorCard from '@/components/shared/errors/AppErrorCard'
import AppTeamworkSync from '@/components/shared/sync/AppTeamworkSync'
import AppSyncBtn from '@/components/shared/sync/AppSyncBtn'
import KeyPriorityDialogForm from '@/components/keyPriorities/dialogs/KeyPriorityDialogForm'
import KeyPriorityListItem from '@/components/keyPriorities/KeyPriorityListItem'
import KeyPriorityListItemDetails from '@/components/keyPriorities/KeyPriorityListItemDetails'
import KeyPriorityDialogLinks from '@/components/keyPriorities/dialogs/KeyPriorityDialogLinks'
import KeyPrioritiesFilterStatus from '@/components/keyPriorities/KeyPrioritiesFilterStatus'

import DataManager from '@/helpers/dataManager'
import FIREBASE_PERMISSION_ERROR_CODES from '@/enums/firebasePermissionErrorCodes'
import KEY_PRIORITIES_STATUSES from '@/enums/keyPriorityStatuses'

const ADD_KP = 'add'
const REMOVE_KP = 'remove'

export default {
  name: 'PageKeyPriorities',
  components: {
    draggable,
    AppPageToolbar,
    AppDialogJSONImportExport,
    AppErrorCard,
    AppTeamworkSync,
    AppSyncBtn,
    KeyPriorityDialogForm,
    KeyPriorityListItem,
    KeyPriorityListItemDetails,
    KeyPriorityDialogLinks,
    KeyPrioritiesFilterStatus
  },
  metaInfo () {
    return {
      title: 'Key Priorities'
    }
  },
  props: {
    showAuthLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      meta: {
        name: '',
        visibility: 'public',
        permissions: {
          domains: []
        },
        twSyncOptions: {}
      },
      data: {
        users: [],
        priorities: []
      },
      keyPriorityFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        rock: '',
        description: '',
        startDate: '',
        endDate: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        deliverables: []
      },
      windowWidth: 0,
      drawerWidth: 250,
      drawerItems: ['All', 'Company', 'Functional', 'Team', 'Personal'],
      activeDrawerItem: 'All',
      keyPrioritiesId: null,
      isLoaded: false,
      hasError: false,
      showDrawer: true,
      showDialogKeyPrioritiesForm: false,
      showDialogKeyPriorityConfirmDelete: false,
      keyPriorityToDelete: null,
      showImportExportModal: false,
      isUpdatingKeyPrioritiesJSON: false,
      dataJSON: '',
      expandedItemIds: [],
      keyPriorityIdToLinkWithL10: null,
      keyPriorityIdToLinkWithVTO: null,
      linkedL10sWithExistingKP: [],
      linkedVTOsWithExistingKP: [],
      isFetchingLinkedItems: false,
      twProjects: [],
      twTasklists: [],
      syncing: false,
      isUpdatingLinks: false,
      isSavingKP: false,
      selectedStatusFilters: []
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'loggedInUserData']),

    contentWidth () {
      let width = this.windowWidth
      if (this.showDrawer) width -= this.drawerWidth
      return `${width}px`
    },

    selectedCategory () {
      return this.drawerItems[this.activeDrawerItem]
    },

    hasPriorities () {
      return this.data.priorities.length > 0
    },

    catFilteredPriorities () {
      return this.activeDrawerItem > 0 ? this.data.priorities.filter(keyPriority => {
        return keyPriority.type === this.selectedCategory
      }) : this.data.priorities
    },

    statusFilteredPriorities () {
      return this.catFilteredPriorities.filter(priority => this.selectedStatusFilters.length === 0 || this.selectedStatusFilters.includes(priority.status))
    },

    hasFilteredPriorities () {
      return this.catFilteredPriorities.length > 0 && this.statusFilteredPriorities.length > 0
    },

    defaultKeyPriorityFields () {
      return {
        email: this.loggedInUser.email,
        rock: '',
        description: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        startDate: this.dataManager.getDateInYYYYMMDD(),
        endDate: this.dataManager.getDateInYYYYMMDD()
      }
    },

    keyPriorityStatuses () {
      const getCountByStatus = (status) => this.catFilteredPriorities
        .reduce((prevVal, kp) => kp.status === status ? parseInt(prevVal) + 1 : prevVal, '0')

      return [{
        id: KEY_PRIORITIES_STATUSES.COMPLETED,
        text: 'Completed',
        color: 'success',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.COMPLETED)
      },
      {
        id: KEY_PRIORITIES_STATUSES.ON_TRACK,
        text: 'On Track',
        color: '#555',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.ON_TRACK)
      },
      {
        id: KEY_PRIORITIES_STATUSES.OFF_TRACK,
        text: 'Off Track',
        color: 'warning',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.OFF_TRACK)
      },
      {
        id: KEY_PRIORITIES_STATUSES.INCOMPLETE,
        text: 'Incomplete',
        color: 'error',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.INCOMPLETE)
      }]
    },

    showLinksDialog () {
      return !!(this.keyPriorityIdToLinkWithL10 || this.keyPriorityIdToLinkWithVTO)
    },

    linkDialogTitle () {
      return this.keyPriorityIdToLinkWithL10 ? 'Select Weekly Meetings' : 'Select Vision & Plans'
    },

    userL10s () {
      return this.loggedInUserData.linkedLevel10s
    },

    userVTOs () {
      return this.loggedInUserData.linkedVTOs
    },

    linkDialogData () {
      return this.keyPriorityIdToLinkWithL10 ? this.userL10s : this.userVTOs
    },

    selectedIds () {
      return this.keyPriorityIdToLinkWithL10 ? this.linkedL10sWithExistingKP : this.linkedVTOsWithExistingKP
    },

    canSync () {
      return !!(this.meta.twSyncOptions.accessToken && this.meta.twSyncOptions.projectId && this.meta.twSyncOptions.tasklistId)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.loadKeyPrioritiesDetailsById(to.params.keyPrioritiesId)
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    // destroy will prevent memory leaks
    this.dataManager.destroy()
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions([
      'setMessage',
      'toggleKeypriorityForItems',
      'syncKeypriority',
      'syncKeyprioritiesFromTeamwork',
      'syncTask',
      'deleteTask'
    ]),

    handleResize () {
      this.windowWidth = window.innerWidth
    },

    loadKeyPrioritiesDetailsById (keyPrioritiesId) {
      this.keyPrioritiesId = null // will stop overwriting saves while we load
      this.dataManager.cancelSubscriptions()

      const handlerError = (error) => {
        this.hasError = true
        this.setMessage({ type: 'error', message: error.message })
        // remove from list if do not have access
        if (FIREBASE_PERMISSION_ERROR_CODES.includes(error.code)) {
          this.dataManager.updateUserReferenceToObject('keypriorities', keyPrioritiesId)
        }
      }

      try {
        this.dataManager.syncObject('keypriorities', keyPrioritiesId, async (error, keyprioritiesObject) => {
          if (error) return handlerError(error)

          const syncOptions = keyprioritiesObject.meta.twSyncOptions || {}
          const hasAccessTokenChanged = syncOptions.accessToken &&
            syncOptions.accessToken !== this.meta.twSyncOptions.accessToken
          const showSyncOptions = syncOptions.accessToken &&
            (!syncOptions.projectId || !syncOptions.tasklistId)
          const isDomainInfoMissing = syncOptions.accessToken && (!syncOptions.domain || !syncOptions.siteName) && !this.isLoaded

          this.meta = { ...this.meta, ...keyprioritiesObject.meta }
          this.data = { ...this.data, ...keyprioritiesObject.data }

          this.keyPrioritiesId = keyPrioritiesId
          this.isLoaded = true
          this.hasError = false

          await this.$nextTick()
          // initially fetch projects and tasklists
          if (hasAccessTokenChanged) this.$refs.appTWSync.fetchSyncedOptions()

          // fetch and add URL to sync options so we can have link to TW task
          // we later added siteName as we are allowing multiple TW accounts
          // add if any  of the option is missing
          if (isDomainInfoMissing) this.$refs.appTWSync.fetchAccountDetails()

          // has accessToekn but not selected any options (e.g. created meeting/rocktool from existing accessToken)
          if (showSyncOptions) this.$refs.appTWSync.showDialogSyncOptions = true
        })
      } catch (error) {
        handlerError(error)
      }
    },

    async loadLinkedL10sAndVTOs (keyPriorityId) {
      try {
        this.isFetchingLinkedItems = true
        const object = await this.dataManager.loadObject('kpMapping', keyPriorityId)
        if (!object.exists) {
          this.createKPMapping(object.id)
          return []
        }

        const { data } = object.data()
        this.linkedL10sWithExistingKP = data.level10s
        this.linkedVTOsWithExistingKP = data.vtos
        return data
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error loading linked items: ${error.message}` })
      } finally {
        this.isFetchingLinkedItems = false
      }
    },

    reloadKeyPrioritiesDetails () {
      this.loadKeyPrioritiesDetailsById(this.$route.params.scorecardId)
    },

    async updateName (newName) {
      try {
        this.$set(this.meta, 'name', newName)
        await this.dataManager.updateObject('keypriorities', this.keyPrioritiesId, {
          'meta.name': this.meta.name
        })
        // We need to also update the name in the list
        this.dataManager.updateObjectNameInList('keypriorities', this.keyPrioritiesId, this.meta.name)
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },

    updatePrivacy (visibility) {
      this.$set(this.meta, 'visibility', visibility)
      this.dataManager.updateObject('keypriorities', this.keyPrioritiesId, {
        'meta.visibility': this.meta.visibility
      })
      this.$analytics('kptool_changed_privacy', { type: this.meta.visibility })
    },

    updatePermissions ({ domains, usersMeta, users }) {
      try {
        this.$set(this.meta, 'permissions', { domains, ...usersMeta })
        this.$set(this.data, 'users', users)

        this.dataManager.saveObject('keypriorities', this.keyPrioritiesId, {
          meta: this.meta,
          data: this.data
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      }
    },

    async deleteKeyPriorities () {
      try {
        this.isLoaded = false
        this.dataManager.cancelSubscriptions()
        await this.dataManager.deleteObject('keypriorities', this.keyPrioritiesId)
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.isLoaded = true
        this.setMessage({ type: 'error', message: `Error deleting key priorities: ${error.message}` })
      }
    },

    addKeyPriority (item = {}) {
      this.keyPriorityFields = {
        ...this.defaultKeyPriorityFields,
        email: item.email || this.loggedInUser.email,
        type: item.type || this.drawerItems[Math.max(1, this.activeDrawerItem)],
        parentRockId: item.id || null,
        startDate: item.startDate || '',
        endDate: item.endDate || '',
        projectId: item.id ? (item.projectId || null) : (this.meta.twSyncOptions.projectId || null),
        tasklistId: item.id ? (item.tasklistId || null) : (this.meta.twSyncOptions.tasklistId || null),
        taskId: null
      }
      this.openDialogKeyPriorityForm()
    },

    editKeyPriority ({ item, e }) {
      if (e && e.target.nodeName === 'A') return
      this.keyPriorityFields = { ...this.defaultKeyPriorityFields, ...item }
      this.openDialogKeyPriorityForm()
    },

    updateKeyPriorityFields (keyPriorityFields, keepForNext) {
      this.keyPriorityFields = Object.assign({}, keyPriorityFields)
      this.saveKeyPriority(keepForNext)
    },

    async saveKeyPriority (keepForNext = false) {
      // validate
      if (!this.keyPriorityFields.email || !this.keyPriorityFields.rock) {
        return this.setMessage({ type: 'error', message: 'Fill in the fields' })
      }

      const parentRockId = this.keyPriorityFields.parentRockId
      let type = 'Key priority'
      let priorities = this.data.priorities
      let parentTaskId = null

      // if it's a deliverable
      if (parentRockId) {
        type = 'Deliverable'
        const parentRockIndex = priorities.findIndex(priority => priority.id === parentRockId)
        parentTaskId = (priorities[parentRockIndex].taskId || null)

        priorities = priorities[parentRockIndex].deliverables
        delete this.keyPriorityFields.deliverables
      }

      // create/updat task on teamwork
      if (this.canSync) {
        try {
          this.isSavingKP = true
          const { data } = await this.syncTask({
            keyPriority: this.keyPriorityFields,
            docId: this.keyPrioritiesId,
            parentTaskId
          })
          this.$set(this.keyPriorityFields, 'taskId', data.keyPriority.taskId)
        } finally {
          this.isSavingKP = false
        }
      }

      // update fields
      if (this.keyPriorityFields.id) {
        const index = priorities.findIndex(keyPriority => keyPriority.id === this.keyPriorityFields.id)
        if (index === -1) return this.setMessage({ type: 'error', message: 'Key priority is deleted' })
        this.$set(priorities, index, this.keyPriorityFields)

        this.syncKeypriority({
          keyPriority: this.keyPriorityFields,
          groupId: this.keyPrioritiesId
        })
      } else { // add to the list
        const id = this.$helpers.generateUniqueId()
        const newRock = { ...this.keyPriorityFields, id: this.$helpers.generateUniqueId() }
        priorities.push(newRock)

        this.syncKeypriority({
          keyPriority: newRock,
          groupId: this.keyPrioritiesId
        })
        // will help us to map keypriority to l10s and vtos
        if (!this.keyPriorityFields.parentRockId) {
          this.createKPMapping(id)
        }
      }

      this.updatePriorities()
      const msg = `${type} ${this.keyPriorityFields.id ? 'updated' : 'added'}`
      if (keepForNext) {
        this.$set(this.keyPriorityFields, 'id', null)
        this.$set(this.keyPriorityFields, 'rock', '')
        this.$set(this.keyPriorityFields, 'description', '')
      } else {
        this.closeDialogKeyPriorityForm()
      }

      this.setMessage({ type: 'success', message: msg })
    },

    createKPMapping (id) {
      this.dataManager.createObject('kpMapping', {
        id,
        name: `L10 & VTO mappings for ${id}`,
        data: {
          groupId: this.keyPrioritiesId,
          keypriorities: [this.keyPrioritiesId],
          level10s: [],
          vtos: []
        }
      })
    },

    updateDate (item) {
      this.syncKeypriority({
        keyPriority: item,
        groupId: this.keyPrioritiesId
      })
      this.updatePriorities()
    },

    updatePriorities () {
      try {
        this.dataManager.updateObject('keypriorities', this.keyPrioritiesId, {
          'data.priorities': this.data.priorities
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error while updating key priorities ${error.message}` })
      }
    },

    openDialogKeyPriorityForm () {
      this.showDialogKeyPrioritiesForm = true
    },

    closeDialogKeyPriorityForm () {
      this.showDialogKeyPrioritiesForm = false
    },

    openDialogKeyPriorityConfirmDelete (item) {
      this.keyPriorityToDelete = item
      this.showDialogKeyPriorityConfirmDelete = true
    },

    async deleteKeyPriority () {
      let type = 'Key priority'
      let priorities = this.data.priorities
      let parentPriority = null

      if (this.keyPriorityToDelete.parentRockId) {
        parentPriority = priorities.find(priority => priority.id === this.keyPriorityToDelete.parentRockId)
        if (!parentPriority) return this.setMessage({ type: 'error', message: 'Key priority not found or is deleted' })

        type = 'Deliverable'
        priorities = parentPriority.deliverables
      } else {
        await this.loadLinkedL10sAndVTOs(this.keyPriorityToDelete.id)
        const linkedItems = [...this.linkedL10sWithExistingKP, ...this.linkedVTOsWithExistingKP]
        if (linkedItems.length > 0) {
          this.updateKeyPriorityLinks(REMOVE_KP, this.keyPriorityToDelete.id, linkedItems)
          this.dataManager.hardDelete('kpMapping', this.keyPriorityToDelete.id)
        }
      }

      const index = priorities.findIndex(keyPriority => keyPriority.id === this.keyPriorityToDelete.id)
      if (index > -1) {
        priorities.splice(index, 1)
        this.updatePriorities()
        // sync
        if (parentPriority) this.syncKeypriority({ keyPriority: parentPriority, groupId: this.keyPrioritiesId })

        // remove task from teamwork if it's synced
        if (this.keyPriorityToDelete.taskId) {
          this.deleteTask({
            keyPriority: this.keyPrioritiesId,
            taskId: this.keyPriorityToDelete.taskId
          })
        }
      }

      this.closeDialogKeyPriorityConfirmDelete()
      this.closeDialogKeyPriorityForm()
      this.setMessage({
        type: 'success',
        message: `${type} deleted`
      })
    },

    closeDialogKeyPriorityConfirmDelete () {
      this.showDialogKeyPriorityConfirmDelete = false
      this.keyPriorityToDelete = null
    },

    openImportExportModal () {
      this.dataJSON = JSON.stringify(this.data, null, 4)
      this.showImportExportModal = true
    },

    async updateKeyPrioritiesJSON (json) {
      try {
        this.isUpdatingKeyPrioritiesJSON = true
        const data = JSON.parse(json)
        this.data = data
        await this.dataManager.updateObject('keypriorities', this.keyPrioritiesId, { data })
        this.closeImportExportModal()
        this.setMessage({ type: 'success', message: 'Data updated' })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      } finally {
        this.isUpdatingKeyPrioritiesJSON = false
      }
    },

    closeImportExportModal () {
      this.showImportExportModal = false
    },

    toggleDetails (id) {
      const index = this.expandedItemIds.indexOf(id)
      index === -1 ? this.expandedItemIds.push(id) : this.expandedItemIds.splice(index, 1)
    },

    linkWithL10 (id) {
      this.keyPriorityIdToLinkWithL10 = id
      this.loadLinkedL10sAndVTOs(id)
    },

    linkWithVTO (id) {
      this.keyPriorityIdToLinkWithVTO = id
      this.loadLinkedL10sAndVTOs(id)
    },

    closeLinksDialog () {
      this.keyPriorityIdToLinkWithL10 = null
      this.keyPriorityIdToLinkWithVTO = null
      this.linkedL10sWithExistingKP = []
      this.linkedVTOsWithExistingKP = []
    },

    async updateKeyPriorityRelations (ids) {
      try {
        this.isUpdatingLinks = true
        // find out newly selected or deleted links
        const checkAgainstList = this.keyPriorityIdToLinkWithL10 ? this.linkedL10sWithExistingKP : this.linkedVTOsWithExistingKP
        const newlySelected = ids.filter(id => !checkAgainstList.includes(id))
        const deletedFrom = checkAgainstList.filter(id => !ids.includes(id))
        // update mapping obj
        const kpId = this.keyPriorityIdToLinkWithL10 || this.keyPriorityIdToLinkWithVTO
        this.keyPriorityIdToLinkWithL10 ? await this.dataManager.updateObject('kpMapping', kpId, {
          'data.level10s': ids
        }) : await this.dataManager.updateObject('kpMapping', kpId, { 'data.vtos': ids })
        this.closeLinksDialog()
        this.setMessage({ type: 'success', message: 'Data updated' })
        // insert or delete items to particular item
        if (newlySelected.length > 0) this.updateKeyPriorityLinks(ADD_KP, kpId, newlySelected)
        if (deletedFrom.length > 0) this.updateKeyPriorityLinks(REMOVE_KP, kpId, deletedFrom)
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating relations: ${error.message}` })
      } finally {
        this.isUpdatingLinks = false
      }
    },

    updateKeyPriorityLinks (type, kpId, itemIds) {
      try {
        const keyPriority = this.data.priorities.find(kp => kp.id === kpId)
        // trigger firebase function to add/remove from particular items
        this.toggleKeypriorityForItems({ type, keyPriority, linkedItems: itemIds.filter(id => id !== this.keyPrioritiesId) })
        this.$analytics('kptool_used_internal_sync')
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error adding key priority to selected item(s): ${error.message}` })
      }
    },

    async fetchTasklists (projectId) {
      await this.$nextTick()
      this.$refs.appTWSync.fetchTasklists(projectId)
    },

    async syncWithTeamwork () {
      try {
        this.syncing = true
        await this.syncKeyprioritiesFromTeamwork(this.keyPrioritiesId)
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error while syncing key priorities with teamwork: ${error.message}` })
        this.$logError({ error })
      } finally {
        this.syncing = false
      }
    },

    goToTWTask (taskId) {
      window.open(`${this.meta.twSyncOptions.domain}#tasks/${taskId}`)
      this.$analytics('kptool_visited_tw_task', { id: taskId })
    },

    setSyncOptions () {
      const options = this.meta.twSyncOptions
      const keyPriorities = this.data.priorities

      // set options and sync key priorities
      keyPriorities.forEach(async (kp) => {
        this.$set(kp, 'projectId', options.projectId)
        this.$set(kp, 'tasklistId', options.tasklistId)

        await this.syncKeypriority({ keyPriority: kp, groupId: this.keyPrioritiesId })

        // set options and sync deliverables
        const deliverables = kp.deliverables || []
        deliverables.forEach(del => {
          this.$set(del, 'projectId', options.projectId)
          this.$set(del, 'tasklistId', options.tasklistId)

          this.syncKeypriority({ keyPriority: del, groupId: this.keyPrioritiesId })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-keypriorities__content-wrapper {
  height: calc(100vh - 96px);
  overflow: hidden;
}

.eos-keypriorities__drawer {
  border-right:1px solid #EEE;
}

.eos-keypriorities__toolbar {
  border-bottom:1px solid #eee;
}

.eos-keypriorities__content {
  height: calc(100vh - 144px);
  overflow-y:auto;

  ::v-deep .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
}

tbody:not(.drag--disabled) {
  ::v-deep .draggable-list-item {
    cursor: move;
  }
}
</style>
